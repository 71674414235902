import { _ } from '../utils.js'

export default (el) => {
    const triggers = _('chatbot-trigger', 'element').nodesFrom(el)
    const overlay = _('chatbot-overlay', 'element').nodeFrom(el)

    const hideShowChatbot = () => {
        overlay.classList.toggle('chatbot-copilot__overlay--active')
    }

    triggers.forEach((trigger) => {
        trigger.addEventListener('click', hideShowChatbot)
    })
}
